<template>
  <el-dialog
    :visible="enterpriseVisble"
    width="900px"
    :title="'账户交易记录'"
    @close="closeDialog"
  >
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="subUser.name" label="姓名"> </el-table-column>
      <el-table-column prop="payPlatform" label="结算中心">
        <template slot-scope="scope">
          {{ scope.row.payPlatform | platformVal }}
        </template>
      </el-table-column>

      <el-table-column prop="amount" label="金额">
        <template slot-scope="scope">
          <span
            :class="{
              red: scope.row.amount * 1 > 0,
              green: scope.row.amount * 1 < 0
            }"
            >{{ scope.row.amount * 1 > 0 ? "+" : "-"
            }}{{ scope.row.amount }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注">
        <template slot-scope="scope">
          {{ scope.row.remark }}
          <router-link
            v-if="scope.row.link"
            :to="{
              path: '/system/task/detail',
              query: { code: scope.row.link.split('@')[1] }
            }"
          >
            <i class="el-icon-link" style="color: #409EFF;font-size:18px;"></i>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { api } from '../../api';
export const FinancialAccountHistoryList = api('/financialAccountHistory')(
  'financialAccountHistory.list.json'
);
export default {
  props: {
    enterpriseVisble: Boolean
  },
  data() {
    return {
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await FinancialAccountHistoryList({
        pageNumber: 1,
        pageSize: 1000,
        type: '1',
        $ignoreRepeat: true
      });
      this.tableData = res.list;
      console.log(this.tableData);
    },
    closeDialog() {
      this.$emit('update:enterpriseVisble', false);
    }
  }
};
</script>

<style></style>
